import { graphql, PageProps } from "gatsby";
import * as React from "react";
import { ColumnLayout } from "../components/columnLayout";
import { NewsPageQuery } from "../generated/graphql";
import Shell from "../layout/shell";

const News: React.FC<PageProps<NewsPageQuery>> = (pageProps) => {
  const newsArticles = pageProps.data.allPrismicNewsArticle.nodes;
  if (!newsArticles) {
    return null;
  }
  return (
    <Shell>
      <div className="fixed-width">
        <h1>News</h1>
      </div>      
      <hr />
      <div className="fixed-width">
        <p className="intro">
        From studio updates and community activity, 
        to film industry initivatives and partnership opportunities
         - All the latest is right here.
        </p>
        {newsArticles && newsArticles.length !== 0 &&
          <div style={{ paddingTop: '50px'}}>
            <ColumnLayout noBorder={true} maxColumns={3} imageHeight={500} items={newsArticles.map(news => ({ title: news.data.title, description: news.data.summary, image: news.data.preview_image, link: `/news/${news.uid}` }))}/>
          </div>
        }
      </div>
    </Shell>
  );
};

export const query = graphql`
  query NewsPage {
    allPrismicNewsArticle {
      nodes {
        uid
        data {
          title
          summary
          preview_image {
            gatsbyImageData
            alt
          }
        }
      }
    }
  }
`;

export default News;
